/* You can add global styles to this file, and also import other style files */

.dark-mode input:-webkit-autofill,
.dark-mode input:-webkit-autofill:focus,
.dark-mode input:-webkit-autofill:hover,
.dark-mode select:-webkit-autofill,
.dark-mode select:-webkit-autofill:focus,
.dark-mode select:-webkit-autofill:hover,
.dark-mode textarea:-webkit-autofill,
.dark-mode textarea:-webkit-autofill:focus,
.dark-mode textarea:-webkit-autofill:hover {
    -webkit-text-fill-color: black;
}